import HomePage from '../../components/body/home/Home';
import Head from 'next/head';

const IndexPage = () => {
  return <HomePage />;
};

export default IndexPage;

export async function getStaticProps(context) {
  return {
    props: {
      pathname: 'relieffactor',
      extraMeta: {
        schemaData: 'homePage',
      },
    },
  };
}
