import { useMediaQuery } from '@mui/material';
import {
  INSIDER_ICON,
  HALLMARK_LOGO,
  HULU_LOGO,
  NBC_LOGO,
  PARAMOUNT,
  ROKU_LOGO,
  USA_LOGO,
  FOX_LOGO,
} from '../../../../commons/components/ui/SvgIcons';
import styles from './AsSeenIn.module.scss';

export default function AsSeenIn() {
  const isTab = useMediaQuery(`(max-width: 550px)`);

  return (
    <div className={styles.asSeenInWrapper}>
      <div className={styles.container}>
        <div className={styles.titleText}>As Seen On</div>
        <div className={styles.iconsContainer}>
          <FOX_LOGO
            height={isTab ? '40.829px' : '40px'}
            width={isTab ? 'auto' : 'auto'}
          />
          <INSIDER_ICON
            height={isTab ? '40.829px' : '40px'}
            width={isTab ? 'auto' : 'auto'}
          />
          <HALLMARK_LOGO
            height={isTab ? '40.829px' : '55px'}
            width={isTab ? 'auto' : 'auto'}
          />
          <HULU_LOGO
            height={isTab ? '40.829px' : '40px'}
            width={isTab ? 'auto' : 'auto'}
          />
          <NBC_LOGO
            height={isTab ? '40.829px' : '55px'}
            width={isTab ? 'auto' : 'auto'}
          />
          <PARAMOUNT
            height={isTab ? '40.829px' : '55px'}
            width={isTab ? 'auto' : 'auto'}
          />
          <ROKU_LOGO
            height={isTab ? '40.829px' : '35px'}
            width={isTab ? 'auto' : 'auto'}
          />
          <USA_LOGO
            height={isTab ? '40.829px' : '40px'}
            width={isTab ? 'auto' : 'auto'}
          />
        </div>
      </div>
    </div>
  );
}
