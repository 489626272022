import styles from './whyReliefFactor.module.scss';
import { useState, useEffect } from 'react';

// MUI
import { Typography, Grid, Stack, Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  DRUG_FREE_ICON_WHITE,
  MANUFACTURED_FDA_REGISTERED_WHITE,
  TESTED_USA_WHITE,
  THIRD_PARTY_PURITY_POTENCY_WHITE,
} from '../../../../commons/components/ui/SvgIcons';
import {
  whyReliefFactorImg,
  whyReliefFactorImgBlur,
} from '../../../../src/images';

export default function WhyReliefFactor() {
  const StyledBlurBg = styled(Box)(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
      width: 442,
      borderRadius: 6,
      marginRight: 30,
    },
    [theme.breakpoints.down('lg')]: {
      width: 442,
      borderRadius: 6,
    },
    [theme.breakpoints.only('md')]: {
      width: 380,
      marginRight: 30,
      borderRadius: 6,
    },
    [theme.breakpoints.down('md')]: {
      width: 328,
      marginTop: 210,
      borderRadius: 6,
    },
    height: 'auto',
    backgroundColor: '#2b2b2b80',
    backdropFilter: 'blur(15px)',
  }));
  const benefitContent = [
    {
      title: '100% Drug-free',
      icon: <DRUG_FREE_ICON_WHITE fill={'#fff'} />,
    },
    {
      title: 'Manufactured in FDA-registered Facility',
      icon: <MANUFACTURED_FDA_REGISTERED_WHITE height={25} />,
    },
    {
      title: '3rd Party Tested for Purity and Potency',
      icon: <THIRD_PARTY_PURITY_POTENCY_WHITE height={25} />,
    },
    {
      title: 'Tested and Packaged in USA',
      icon: <TESTED_USA_WHITE height={25} />,
    },
  ];
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(false);
  });
  return (
    <Box className={styles.Main_Parent}>
      <Box className={styles.Parent_bgContainer}>
        <Container
          sx={{
            width: '100%',
            height: '100%',
            backgroundImage: isLoading
              ? `url(${whyReliefFactorImgBlur})`
              : `url(${whyReliefFactorImg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            borderRadius: { md: 1.5 },
            maxWidth: { xl: 'xl', lg: '960px', md: '90%' },
          }}
          className={styles.bgContainer}
        >
          <Stack
            display="flex"
            flexDirection="row"
            minHeight="600px"
            alignItems="center"
            margin="auto"
            sx={{
              maxWidth: { lg: '1140px', md: '688px', xs: '95%' },
              justifyContent: { lg: 'flex-end', md: 'flex-end', sm: 'center' },
            }}
          >
            <StyledBlurBg sx={{ p: { lg: 6, md: 4, sm: 2, xs: 2 } }}>
              <Grid
                container
                display="flex"
                flexDirection="column"
                wrap="nowrap"
              >
                <Grid item xs>
                  <Typography
                    variant="h2"
                    color="primary.white"
                    sx={{ pb: { lg: 1, sm: 1 } }}
                  >
                    Trusted for More Than a Decade.
                  </Typography>
                  <Typography variant="p1" color="primary.white">
                    Over one million customers have chosen our 100% drug-free
                    health supplements to help them feel their best.
                  </Typography>
                </Grid>
                <Grid item xs sx={{ py: { lg: 2, md: 1, sm: 2, xs: 1 } }}>
                  <Grid container rowGap={{ lg: 4, md: 2, sm: 1, xs: 1 }}>
                    {benefitContent.map((item) => (
                      <Grid item xs={6} key={item}>
                        <Stack
                          display="flex"
                          direction="row"
                          alignItems="flex-start"
                          columnGap={{ lg: 2, md: 1, sm: 1, xs: 1 }}
                        >
                          <Box sx={{ width: 24, height: 24 }}>{item.icon}</Box>
                          <Typography variant="h5" color="primary.white">
                            {item.title}
                          </Typography>
                        </Stack>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </StyledBlurBg>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
